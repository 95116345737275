import { useEffect } from 'react';

import { Result } from '@arco-design/web-react';

import './index.less';

export default function Redirect() {
  useEffect(() => {
    document.body.classList.add('fixedBody');
    return () => document.body.classList.remove('fixedBody');
  });

  return <Result status="success" title="操作成功！" />;
}
