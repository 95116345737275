import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
  useNavigate,
} from 'react-router-dom';

import { AuthProvider } from '@ausz/auth';
import { PageLoading } from '@ausz/ui';

import Environment from '@/config/EnvironmentConfig';
import Redirect from '@/pages/Redirect';

const Home = lazy(() => import('@/pages/Home'));
const Privacy = lazy(() => import('@/pages/Privacy'));
const Terms = lazy(() => import('@/pages/Terms'));
const PageNotFound = lazy(() => import('@/pages/404'));

function Container() {
  const navigateFn = useNavigate();

  return (
    <AuthProvider
      authConfig={{
        host: Environment.host,
        loginPage: '/',
        loginRedirectURL: '/basement',
        logoutRedirectURL: '/',
        navigateFn,
      }}
    >
      <Outlet />
      <ScrollRestoration />
    </AuthProvider>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Container />}>
      <Route path="/" element={<Home />} />
      <Route path="/redirect" element={<Redirect />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);

export default function RootRoute() {
  return (
    <Suspense fallback={<PageLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
