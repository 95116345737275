import { Provider } from 'react-redux';

import { ConfigProvider } from '@arco-design/web-react';
import '@arco-themes/react-homepage/css/arco.css';
import { ThemeProvider } from '@ausz/ui';

import RootRoute from '@/pages';
import configureStore from '@/store';

function App() {
  return (
    <ThemeProvider defaultTheme="light">
      <ConfigProvider
        autoInsertSpaceInButton={false}
        focusLock={{ modal: false, drawer: false }}
        componentConfig={{
          InputNumber: { hideControl: true },
          Form: {
            autoComplete: 'off',
          },
        }}
      >
        <Provider store={configureStore()}>
          <RootRoute />
        </Provider>
      </ConfigProvider>
    </ThemeProvider>
  );
}
export default App;
